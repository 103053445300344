<template>
  <div class="cover">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>How it works</h4>
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/e7fhU_OP5FA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

        </div>
      </div>
    </div>
    <div class="heading">
      &copy; 2022 by Cygital Softwares LTD
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.cover{
  
  text-align: center;
}
.heading{
  padding: 1.2em 0;
  border-top: solid 1px #ccc;
}

.heading span{
  font-size: 2em;
}

.icon{
    height: 18px;
    margin: 0 1em
}

.content{
  padding-bottom: 5em;
}

.input-group input, .input-group-text{
  border-radius: 3em !important;
}
.input-group input{
  margin-left: 10%;
  background: #dceaee;
}
.input-group .input-group-text{
  margin-left: -2em !important;
  z-index: 1;
  background: #2d398f;
  color:#fff;
  padding-left:2em;
  padding-right:2em;
}

</style>
