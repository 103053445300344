
export default {
  install: (app) => {
    app.config.globalProperties.$t = (data) =>{
      return data
    },
    app.config.globalProperties.$api = () =>{
      let hostpath = document.location.hostname.split(".");
      var backendUrl = "https://school.i.ng/site/"
      switch(hostpath[0]){
          case 'localhost':
              backendUrl = "http://localhost:8080/local/school_i_ng/site/"
              break;
      }
      return backendUrl;
    },
    //delay before execution
    app.config.globalProperties.$debounce = (function () {
      var timer = 0;
      return function (callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
      };
    })()

  }
}