<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: justify;
}

.icon{
    height: 26px;
    /*margin-top: -3px;
    filter: invert(50%) sepia(30%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);*/
}

.act_btn{
  border:1px;
  border-radius: 1em;
  padding:0.5em 2em;
  background: #2d398f;
  color:#fff;
}
a{
  text-decoration: none !important;
  /*cursor: pointer;*/
}
/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/

</style>
