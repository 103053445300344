<template>
  <div>
    <header>
      <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><img class="logo" src="@/assets/logo.png" /> </a>
          <button v-on:click="toggleMenu"  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <img class="icon" src="@/assets/svg/list.svg" />
          </button>
          <div class="collapse navbar-collapse bg-dark" :class="{show:showMenu}" id="navbarCollapse">
            <div class="me-auto"></div>
            
            <a class="act_btn_top" @click="goTo('register')">Get started</a> &nbsp; &nbsp; 
            <a class="act_btn_top" :href="'login'">Go to App</a>
           
          </div>
        </div>
        
      </nav>

    </header>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class = "call_to_action">
              <div class="act_1">
                School.I.NG is a cloud-based school management solution catering to primary and secondary schools of all sizes.
              </div>
              <div class="act_2">
                <a class="btn btn-success" @click="goTo('register')">Get started</a> or Call <b> 0705 1244 152</b> for more
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu:false
    }
  },
  mounted () {
    let obj = this;
    document.title = obj.$t("School");
  },
   methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    goTo(e){
      var elementTo = document.getElementById(e);
      elementTo.scrollIntoView({ block: 'center',  behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
header{
  position: relative;
  z-index: 1;
}
nav{
  display: block;
}
.navbar{
  opacity: 0.9;
  /*background:#dceaee !important;*/
}
.navbar-nav{
  display: flex;
}
.lower_link{
  display: block;
}
.nav-item{
  padding: 0 1em;
}
.nav-link{
  text-transform: uppercase;
  font-weight: bold;
  color:#fff;
  font-size: 12px;
}
.nav-link:hover{
  color:#eee;
}
.call_to_action{
  border-bottom:solid 1px #ccc;
  padding:2em;
}
.act_1{
  font-size: 1.5em;
}

.act_2{
  text-align: center;
}


.act_btn{
  margin-top: 1em;
  margin-bottom: 1em;
  display: inline-block;
}

.act_btn_top{
  border:1px;
  border-radius: 1em;
  padding:0.5em 1em;
  background: #fff;
  color:#2d398f;
  cursor: pointer;
}
.lang{
  border:solid 1px #eee;
  margin: 0 0.5em;
  border-radius: 1em;
  padding:0.2em 0.5em;
  color:#fff;
  cursor: pointer;
}

.div_bg {
  text-align: right;
  position:absolute;
  top:0;
  z-index: -1;
  width:100%;
  height: 700px;
}
.div_bg:lang(ar){
  text-align: left;
}

.bg{
  width: 70%;
}
.bg:lang(ar){
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.logo{
    height: 56px;
}

.icon{
    height: 28px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}



@media(max-width:744px) {
    /*.navbar .navbar-nav>.nav-item {
        float: none;
        margin-left: .1rem;
    }*/
    .container-fluid{
      padding: 0;
    }
    
    .navbar .collapse{
      background: rgba(120, 123, 89, .6);
      padding: 0 0 1em 1em ;
    }
}

</style>
