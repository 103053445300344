<template>
  <div class="cover" id="register">
    <!--
    <div class="heading">
      <span>Do you need custom offer?</span> <button class="act_btn">{{ $t("contact us") }}</button>
    </div>
    -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 unit">
            <form class="row g-3" @submit="create">
              <div v-if="success" class="report">
                <div class="alert alert-success" role="alert">
                  Great! your account was successfully created
                </div>
                You may login and continue with teachers and student record entries.<br>&nbsp;<br>
                <a class="btn btn-success" :href="'login'">Continue</a>
              </div>
              <template v-else>
                <div class="col-12">
                  <label for="name" class="form-label">School Name</label>
                  <input type="text" class="form-control" :class="{'is-invalid':errors.name}" id="name" v-model="formFields.name" placeholder="School name">
                  <div class="invalid-feedback" v-html="errors.name"></div>
                </div>

                <div class="col-12">
                  <label for="address" class="form-label">Address</label>
                  <input type="text" class="form-control" :class="{'is-invalid':errors.address}" id="address" v-model="formFields.address" placeholder="School address">
                  <div class="invalid-feedback" v-html="errors.address"></div>
                </div>

                <div class="col-md-6">
                  <label for="phone" class="form-label">Phone Number</label>
                  <input type="phone" class="form-control" :class="{'is-invalid':errors.phone}" id="phone" v-model="formFields.phone" placeholder="School phone number">
                  <div class="invalid-feedback" v-html="errors.phone"></div>
                </div>
                <div class="col-md-6">
                  <label for="email" class="form-label">Email Address</label>
                  <input type="email" class="form-control" :class="{'is-invalid':errors.email}" id="email" v-model="formFields.email" placeholder="School email address">
                  <div class="invalid-feedback" v-html="errors.email"></div>
                </div>
                <div class="col-md-12">
                  <label for="password" class="form-label">Create Password</label>
                  <div class="input-group mb-3">
                    <input type="password" class="form-control password" :class="{'is-invalid':errors.password}" v-model="formFields.password">
                    <div class="input-group-text">
                      Show &nbsp; <input class="form-check-input mt-0 state" type="checkbox" v-model="formFields.state" @click="togglePassword" value="" >
                    </div>
                    <div class="invalid-feedback" v-html="errors.password"></div>
                  </div>
                  
                </div>

                <div class="col-12">
                  <button class="btn btn-success">Create Account</button> &nbsp; <span v-if="loading" class="wait"> Working...</span>
                  &nbsp; Already a user? <a :href="'login'">Sign In</a>
                </div>
              </template>
            </form>
          </div>

          <div class="col-lg-6 unit sep">
            <h2>Create your school account</h2>
            <p>You can start using the application as soon as you are done with your school account creation.</p>
            <hr>
            <h4>For demo, use the following login</h4>
            <p>User: 080 <br> Password: 080</p>
            <a :href="'login'">Click here to continue</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      loading:false,
      success:false,
      errors: {},
      formFields: {
        name:"", 
        address:"",
        phone:"",
        email:"",
        password:"",
        state: false
      }
    }
  },
  methods: {
    togglePassword(){
      let obj = this;
      const passwordField = document.querySelector('.password');
      if(obj.formFields.state){
        passwordField.setAttribute('type', 'password');
      } else {
        passwordField.setAttribute('type', 'text');
      }
    },
    create(e){
      let obj = this;
      obj.loading = true;
      var form_data = new FormData();
      for (var key in obj.formFields ) {
          form_data.append(key, obj.formFields[key]);
      }

      fetch(obj.$api()+"schools", {
        method: 'post',
        body: form_data,
      })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        obj.loading = false;
        if(data.success == 0){
          obj.errors = data.error;
        }
        if(data.success == 1){
          obj.success = true;
        }
      })
      .catch(error => {
        obj.loading = false;
        alert(error)
      });
      
      e.preventDefault()
    }
   }
}

</script>

<style scoped>
form{
  padding: 2em 0;
}
a{
  color:#fff;
}
.cover{
  margin: 3em 0;
  padding-bottom: 3em;
}
.cover .unit{
  display: grid;
  justify-content: center;
  align-content: center;
}
.report{
  padding: 2em 0;
}
.wait{
  color: #ffaa00;
}
.heading{
  /*text-transform: uppercase;*/
  background: #2d398f;
  color:#fff;
  padding: 1.2em 0;
}

.heading span{
  font-size: 2em;
}

.content{
  background:#333;
  color:#fff;
}

/*
.content .col-lg-6{
  padding: 4em 0;
}
*/

.sep{
  border-left: solid 1px #444;
}

.act_btn{
  border:solid 1px #fff;
  margin-left: 3em;
  
}


.invalid-feedback {
    color: #db9700;
}
</style>
