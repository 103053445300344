<template>
  <div>
    <Header /> 
    <main>
      <Features />
      <Register />
      <Footer />
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Features from '@/components/Features.vue'
import Register from '@/components/Register.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Features,
    Register,
    Footer
  }
}
</script>
