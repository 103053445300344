<template>
  <div class="feature" id="features">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <h1 class="heading">Features</h1>
        </div>
      </div>
     
     <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/people.svg" />
          </div>
          <div>User Management - Students, Parents and Teachers Information</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/book.svg" />
          </div>
          <div>Examination processing and report generation</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/check2-circle.svg" />
          </div>
          <div>Student Attendance</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/chat-left-text.svg" />
          </div>
          <div>Messaging System</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/bank.svg" />
          </div>
          <div>Financial Report</div>
      </div>
    
      <div class="col">
          <div class="box">
          <img class="icon" src="@/assets/svg/cash.svg" />
          </div>
          <div>Ability to accept school fees online</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/wallet.svg" />
          </div>
          <div>Scratch Card Management System</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/code.svg" />
          </div>
          <div>API access</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/arrow-down-up.svg" />
          </div>
          <div>Applicant Registration Online</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/sim.svg" />
          </div>
          <div>Advance examination report generation</div>
      </div>
    
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/globe.svg" />
          </div>
          <div>Custom Domain and Website</div>
      </div>
      
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/telephone.svg" />
          </div>
          <div>24/7 Customer Support</div>
      </div>
    </div> 

    

    </div>
  </div>
</template>

<script>
export default {
  name: 'Features'
}
</script>

<style scoped>
.feature{
  margin: 3em 0;
  padding-bottom: 3em;
  text-align-last: center;
}
.heading{
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0;
}
/*
.icon{
    width: 7em;
    height: 7em;
    margin: 0.5em 0;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}*/
.col{
  font-size: 1em;
  margin-bottom: 2em;
}
.box{
border-top: solid 1px #eee;
padding-top: 1em;
 margin: 0 2em;
}
</style>
